<template>
  <v-container>
    <v-row class="text-center">
    <form>
    <v-text-field
    v-model="name"
	@keydown.enter="submit()"
    :error-messages="nameErrors"
	:counter="6"
    label="动态口令"
    ></v-text-field>

	<!--
    required
    @input="$v.name.$touch()"
    @blur="$v.name.$touch()" -->

    <v-btn
    class="mr-4"
    @click="submit()"
    >
动态口令登录
    </v-btn>
    <v-btn @click="clear()">
百度账号登录
    </v-btn>
    </form>
	
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
      name: { required, maxLength: maxLength(6) , minLength: minLength(6)},
      passwd: { required }
    },

    data: () => ({
      name: '',
      passwd: '',
    }),

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
		!this.$v.name.maxLength && errors.push('6位有效口令')
		!this.$v.name.minLength && errors.push('6位有效口令')
        !this.$v.name.required && errors.push('口令不能为空')
        return errors
      },
    },

    methods: {
		submit () {
			window.location.href = "/login?name=" + this.name;
		},
		clear () {
			if(/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent)) {
				window.location.href = "https://openapi.baidu.com/oauth/2.0/authorize?response_type=code&client_id=QPQSfa0ZbGFiYS0fecszVhmpX9fhS8z6&redirect_uri=https://my.wcz.pub/baiduapi/redirect&scope=basic,netdisk&display=mobile&force_login=1&state=new";
			} else {
				window.location.href = "https://openapi.baidu.com/oauth/2.0/authorize?response_type=code&client_id=QPQSfa0ZbGFiYS0fecszVhmpX9fhS8z6&redirect_uri=https://my.wcz.pub/baiduapi/redirect&scope=basic,netdisk&display=web&force_login=1&state=new";
			}
		},
    },
  }
</script>
